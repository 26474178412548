
import Vue from "vue";

import http from "@/http";

import { date, excerpt } from "@/filters";

import { NoticeResponse, Notice, Breadcrumb } from "@/interfaces";

import { mdiPlus, mdiUndo, mdiPublish } from "@mdi/js";

export interface DataType {
  notice: Notice[];
  selected: Notice[];
}

export default Vue.extend({
  filters: {
    date,
    excerpt
  },
  data(): DataType {
    return {
      notice: [],
      selected: []
    };
  },
  computed: {
    icon() {
      return { mdiPlus, mdiUndo, mdiPublish };
    },
    headers() {
      return [
        {
          text: "作成日",
          value: "created_at"
        },
        {
          text: "作成者",
          value: "user"
        },
        {
          text: "タイトル",
          value: "title"
        },
        {
          text: "コンテンツ",
          value: "content"
        },
        {
          text: "添付",
          value: "attachments"
        },
        { text: "", value: "action", sortable: false }
      ];
    }
  },
  watch: {
    async $route() {
      await this.fetchData();
    }
  },
  async mounted() {
    await this.fetchData();
  },
  methods: {
    async fetchData() {
      const { target } = this.$route.params;

      const { data } = await http.get<Notice[]>("notices", {
        params: {
          target
        }
      });

      this.notice = data;
    },
    click(item: Notice) {
      const to = `/notice/${item.id}`;

      this.$router.push(to);
    },
    add() {
      const { target } = this.$route.params;

      const path = `/notice/create`;

      this.$router.push({
        path,
        query: {
          target
        }
      });
    },
    async publish() {
      for (const selected of this.selected) {
        const url = `notices/${selected.id}`;

        const { data } = await http.put<Notice>(url, {
          status: "publish"
        });

        const index = this.notice.findIndex(notice => {
          return notice.id === data.id;
        });

        if (index) {
          this.notice.splice(index, 1, data);
        }
      }
    },
    async undo() {
      for (const selected of this.selected) {
        const url = `notices/${selected.id}`;

        const { data } = await http.put<Notice>(url, {
          status: "draft"
        });

        const index = this.notice.findIndex(notice => {
          return notice.id === data.id;
        });

        if (index) {
          this.notice.splice(index, 1, data);
        }
      }
    }
  }
});
